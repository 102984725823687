// src/App.js
import React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome to Etaba Consulting</h1>
      </header>
      <div>
      <Card>
        <CardMedia
          component="img"
          alt="Description of the image"
          width="200"
          height="450"
          image={process.env.PUBLIC_URL + '/coming_soon.png'}
          title="Image Title"
        />
      </Card>
    </div>
    <div>
      <img
        src={process.env.PUBLIC_URL + '/coming_soon.png'}
        alt="Description of the image"
        width="200"
        height="150"
      />
    </div>
    </div>
  );
}

export default App;
